const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://kkwgsxtofl.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://p7om6uvfsk.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.dev.laika.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.laika.forwoodsafety.com',
    WEBSOCKET: 'wss://1wnibn8oa1.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;